<template>
  <div>
    <!-- BEGIN NAVBAR -->
    <NavHeader></NavHeader>
    <!-- END NAVBAR -->

    <!-- BEGIN MAIN CONTAINER -->
    <div
      class="main-container"
      id="container"
      :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
    >
      <!-- BEGIN OVERLAY -->
      <div
        class="overlay"
        v-shortkey="['ctrl', 'b']"
        @shortkey="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
        :class="{ show: !$store.state.is_show_sidebar }"
        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
      ></div>
      <div
        class="search-overlay"
        :class="{ show: $store.state.is_show_search }"
        @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
      ></div>
      <!-- END OVERLAY -->

      <!-- BEGIN SIDEBAR -->
      <Sidebar :pathURL="$route.path"></Sidebar>
      <!-- END SIDEBAR -->

      <!-- BEGIN CONTENT AREA -->
      <div
        id="content"
        class="main-content"
      >
        <router-view v-if="branchReady">
          <!-- BEGIN LOADER -->
          <div
            id="load_screen"
            v-if="loading"
          >
            <div class="loader">
              <div class="loader-content">
                <div class="spinner-grow align-self-center"></div>
              </div>
            </div>
          </div>
          <!-- END LOADER -->
          <!-- Actual content will be here, replace this comment with your actual content -->
        </router-view>

        <!-- BEGIN FOOTER -->
        <div class="d-flex justify-content-end mr-3">v1.6.4</div>
        <!-- <Footer></Footer> -->
        <!-- END FOOTER -->
      </div>
      <!-- END CONTENT AREA -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!-- <app-settings /> -->
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>

<script>
  import NavHeader from '@/components/layout/NavHeader.vue';
  import Sidebar from '@/components/layout/sidebar.vue';

  export default {
    components: {
      NavHeader,
      Sidebar,
      // Footer,
      // appSettings,
    },
    data() {
      return {
        loading: false, // State to manage loading
        branchReady: true, // State to control the content rendering
      };
    },
    watch: {
      '$store.state.branchData': {
        handler(newBranchData) {
          this.reloadMainContent(newBranchData);
        },
        immediate: true,
      },
    },
    methods: {
      reloadMainContent() {
        this.loading = true; // Start loading animation
        this.branchReady = false; // Temporarily hide the content

        // Simulate a data fetch or processing time
        setTimeout(() => {
          this.branchReady = true; // Show the content again
          this.loading = false; // Stop loading animation
        }, 500); // Adjust delay as needed
      },
    },
  };
</script>
